<i18n lang="json">
{
  "en": {
    "close": "Close"
  },
  "fr": {
    "close": "Fermer"
  }
}
</i18n>

<template>
  <TransitionRoot as="template" :show="show">
    <Dialog as="div" class="relative z-40" @close="closeHandler">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-primary-500/50 transition-opacity" />
      </TransitionChild>

      <div
        class="fixed inset-0"
        :class="{
          'bottom-0': !isTop,
          'overflow-hidden': !isTop,
          'top-[3.125rem]': isTop
        }"
      >
        <div class="absolute inset-0" :class="{ 'overflow-hidden': !isTop }">
          <div
            class="pointer-events-none fixed flex items-end max-w-full"
            :class="{
              'bottom-0': !isTop,
              'top-[3.125rem]': isTop,
              'inset-y-0': !isTop
            }"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              :enter-from="isTop ? 'translate-y-0' : 'translate-y-full'"
              enter-to="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              :leave-from="isTop ? 'translate-y-full' : 'translate-y-0'"
              :leave-to="isTop ? 'translate-y-0' : 'translate-y-full'"
            >
              <DialogPanel class="pointer-events-auto w-dvw">
                <div
                  class="flex h-auto flex-col bg-white shadow-xl"
                  :class="{
                    'rounded-b-2xl': isTop,
                    'rounded-t-2xl': !isTop
                  }"
                >
                  <div class="flex min-h-0 max-h-lvh flex-1 flex-col py-6" :class="{ 'overflow-y-auto': !isTop }">
                    <div v-if="$slots.title" class="px-4 mb-4 sm:px-6">
                      <div class="flex items-start justify-between">
                        <div class="typography-subtitle-2">
                          <slot name="title" />
                        </div>
                      </div>
                    </div>
                    <div class="relative flex-1 px-6">
                      <slot name="content" />
                    </div>
                  </div>
                  <div v-if="$slots.buttons" class="flex flex-shrink-0 justify-end p-4">
                    <slot name="buttons" />
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'

const props = withDefaults(
  defineProps<{
    show?: boolean
    slide: 'slide-top' | 'slide-bottom'
  }>(),
  {
    slide: 'slide-bottom'
  }
)

const isTop = computed(() => props.slide === 'slide-top')

const emit = defineEmits<{
  close: [value: void]
}>()

const closeHandler = () => emit('close')
</script>
